.topheaderSection {
  background: #f9f8f2;
  padding: 12px 0px;
  // font-family: var(--Roboto) !important;
  font-family: 'pangramregular' !important;
  position: relative;
  z-index: 1000;

  .topheaderInner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .fast-delivery {
      color: #4a4a4a;
      transition: 0.3s all ease;
      text-decoration: none;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      color: #e57200;
      cursor: pointer;
      svg {
        margin-right: 5px;
      }

      &.view:hover {
        color: #e57200;
      }

      img {
        vertical-align: middle;
        margin-bottom: 9px;
        // float: left;
        height: 19px;
        width: 19px;
      }

      span {
        padding-left: 5px;
        display: block;
      }
    }

    .topheaderLeft {
      display: flex;
      position: relative;

      .topLocation {
        display: flex;
        align-items: center;

        img {
          height: 19px;
          width: 19px;
        }

        p {
          font-size: 13px;
          line-height: normal;
          color: #333333;
          font-weight: normal;
          margin: 0px;
          margin-left: 3px;

          span {
            color: #333333;
            font-family: 'robotomedium';
            font-size: 14px;
            // margin-left: 3px;
          }
        }

        // a {
        //   img {
        //     margin-left: 5px;
        //   }
        // }
      }

      .topheaderapply {
        background-color: #fff;
        position: absolute;
        z-index: 9999;
        overflow: hidden;
        width: 375px;
        box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.2);
        padding: 10px 15px;
        border-radius: 3px;
        left: 0px;
        top: 30px;

        .Closeapply {
          margin-bottom: 5px;
          display: block;
          float: right;
        }

        .topapplycard {
          position: relative;
          display: inline-block;
          width: 100%;
          position: relative;

          .field__label {
            color: #333333;
            font-size: 13px;
            line-height: 1;
            opacity: 1;
            overflow: hidden;
            pointer-events: none;
            position: absolute;
            padding: 0 5px;
            text-overflow: ellipsis;
            top: -5px;
            left: 10px;
            display: inline-block;
            text-align: left;
            user-select: none;
            white-space: nowrap;
            background: #fff;
            z-index: 1;
          }

          .updatepin {
            position: absolute;
            right: 5px;
            top: 5px;
            bottom: 5px;
            background: #E27A34;
            color: #fff;
            border-radius: 3px;
            letter-spacing: 0.01em;
            font-size: 14px;
            cursor: pointer;
            padding: 5px 10px;
          }

          .formControl {
            background-color: #fff;
            border: 1px solid #e5e5e5;
            padding: 6px 10px;
            border-radius: 2px;
            box-shadow: none;
            height: 45px;
            width: 100%;
            font-size: 14px;
            line-height: 22px;
            font-weight: 400;
            color: #202020;
            transition: all 0.2s ease-out;
          }

          .formControl:disabled {
            cursor: not-allowed;
          }

          .applybtn {
            position: absolute;
            right: 5px;
            top: 5px;
            bottom: 5px;
            background-color: #E27A34;
            color: #fff;
            border-radius: 3px;
            letter-spacing: 0.01em;
            font-size: 14px;
            line-height: 25px;
            cursor: pointer;
            padding: 5px 10px;
          }
        }

        .pinError {
          color: red;
          display: block;
          font-size: 13px;
        }

        .relatestore {
          margin-top: 20px;

          b {
            color: #333333;
            font-family: 'robotomedium';
            font-size: 14px;
            margin-left: 3px;
          }

          .storeBox {
            padding-top: 12px;

            li {
              border-bottom: 1px solid #e5e5e5;
              margin: 0 0 6px;
              padding: 0 0 6px;
              display: block;
              border-left: 0;
              font-size: 12px;

              a {
                color: #4a4a4a;
                transition: 0.3s all ease;
                text-decoration: none;
                display: flex;
                align-items: flex-start;
                justify-content: flex-start;

                &.view:hover {
                  color: #e57200;
                }

                img {
                  vertical-align: middle;
                  margin-bottom: 9px;
                  // float: left;
                  height: 19px;
                  width: 19px;
                }

                span {
                  padding-left: 5px;
                  display: block;
                }
              }
            }

            li:last-child {
              border-bottom: 0;
              margin-bottom: 0;

              a {
                color: #E27A34;

                span {
                  display: block;
                  padding-left: 5px;
                }
              }
            }
          }
        }
      }

      li:nth-child(n + 2) {
        font-size: 13px;
        margin-left: 10px;
        padding-left: 10px;
        display: inline-block;
        border-left: 1px solid #b3b3b3;

        a {
          color: #E27A34;

          svg {
            width: 28px;
            height: 25px;
            margin-top: -2px;
            margin-right: 5px;
            vertical-align: middle;
          }
        }
      }
    }

    .topheaderRight {
      display: flex;
      align-items: center;

      li {
        padding: 0px 8px;
        display: inline-flex;
        align-items: center;
        justify-content: flex-start;
        min-height: 22px;

        &:last-child {
          padding-right: 0px;
        }

        .topheaderLinks {
          display: flex;
          align-items: center;

          img {
            margin-right: 5px;
          }

          p {
            color: #4a4a4a;
            font-size: 13px;
            line-height: normal;
            margin: 0;
            transition: 0.3s all ease;
            font-family: 'robotoregular';
          }

          &:hover {
            p {
              color: #e57200;
            }
          }
        }
      }

      li:nth-child(1) {
        img {
          width: 15px;
          height: 15px;
        }
      }

      li:nth-child(n + 2) {
        border-left: 1px solid #b3b3b3;
        padding-left: 10px;
      }

      li:nth-child(2) {
        img {
          width: 23px;
          height: auto;
          margin-top: -5px;
        }
      }

      li:nth-child(3) {
        img {
          width: 17px;
          height: 17px;
          margin-top: -1px;
        }
      }
    }
  }
}

.delivery-inner-block {
  .flexbox {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    column-gap: 20px;
    padding: 25px 20px 10px;
    background: #ffeee6;
    border-bottom: 1px solid #e7e7e7;
    margin-bottom: 20px;
    border-radius: 10px 10px 0 0;

    .delivery-heading {
      font-size: 21px;
      line-height: 23px;
      font-family: 'pangrammedium';
      color: #E27A34;
      letter-spacing: 0.01em;
      text-align: left;

      .delivery-subhead {
        font-size: 14px;
        line-height: 18px;
        display: block;
        text-align: left;
        font-family: 'pangramregular';
        color: #646464;
      }
    }
  }

  .field__form {
    padding: 10px 30px 35px;
    .thankyou {
      font-family: 'pangramregular';
    }
    .form {
      display: flex;
      flex-wrap: wrap;
      gap: 15px;
    }

    .formgroup {
      display: flex;
      position: relative;
      margin-bottom: 15px;
      flex-direction: column;
      flex: 1 1 calc(50% - 15px);

      label {
        font-family: 'pangramregular';
        color: #4b4a4a;
        font-size: 13px;
        opacity: 0;
        line-height: 1;
        overflow: hidden;
        pointer-events: none;
        position: absolute;
        padding: 0 5px;
        text-overflow: ellipsis;
        top: -5px;
        left: 10px;
        display: inline-block;
        text-align: left;
        transform: translateY(3px);
        transition: all 0.2s ease-out;
        user-select: none;
        white-space: nowrap;
        background: #fff;
        z-index: 1;

        &.field__label {
          opacity: 1;
          transform: none;
        }
      }

      input {
        width: 100%;
        height: 50px;
        font-size: 14px;
        padding: 0 15px;
        color: #4b4a4a;
        border-radius: 4px;
        border-radius: 2px;
        background-color: #fff;
        border: 1px solid #e5e5e5;
        border: 1px solid #d1d1d1;
        font-family: 'pangramregular';
        transition: all 0.2s ease-out;

        &.texterror {
          border-color: #e32526;
        }

        &:focus {
          border-color: #e65c00;
        }
      }

      p {
        text-align: left;
        font-size: 12px;
        font-family: 'pangramregular';
        text-transform: capitalize;
        padding: 3px 0px 0px;
        color: red !important;
        display: block;
      }
    }
  }
}

@media (max-width: 1280px) {
  .topheaderSection {
    padding: 8px 40px;
  }
}

@media (max-width: 767px) {
  .topheaderSection {
    display: none !important;

    .topheaderInner {
      .topheaderLeft {
        .topLocation {
          p {
            font-size: 12px;
            line-height: 15px;
            margin-left: 2px;

            span {
              font-size: 13px;
            }
          }
        }

        .topheaderapply {
          width: 266px;
          padding: 10px 12px;
          top: 34px;
        }
      }

      .topheaderRight {
        li {
          padding: 0px 8px;

          .topheaderLinks {
            img {
              margin-right: 4px;
            }

            p {
              font-size: 12px;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 575px) {
  .topheaderSection {
    .topheaderInner {
      .topheaderLeft {
        .topLocation {
          p {
            font-size: 12px;
            line-height: 15px;
            margin-left: 2px;

            span {
              font-size: 12px;
            }
          }
        }
      }

      .topheaderRight {
        li {
          padding: 0px 7px;

          .topheaderLinks {
            img {
              margin-right: 4px;
              width: 18px;
            }

            p {
              display: none;
            }
          }
        }
      }
    }
  }
}


// .topheaderapply {
//   overflow: hidden; /* Prevent content overflow when height is 0 */
//   max-height: 0; /* Initially hidden */
//   transition: max-height 5s ease; /* Apply smooth transition on max-height */
// }

// .pinned {
//   max-height: 500px; /* A large value that is enough to contain the content */  
//   height: auto;
//   transition: height 5s ease;
// }

.topheaderapply {
  padding:  0 !important;
}

.topheaderapply .inner-pinned{
  padding: 10px 15px !important;
}

.topheaderapply .inner-pinned input {
  padding-top: 12px !important;
}

@media (max-width: 767px) {
  .topheaderapply .inner-pinned{
    padding: 10px 12px !important;
  }
}