// filter-section
.filterssortsection {
  border-top: 1px solid #f5f5f5;
  background-color: #fff;
  transition: all 0.4s ease;

  .filterssortinner {
    border-bottom: 1px solid #f5f5f5;
    padding: 20px 0px 15px;
  }

  .filterssorcontent {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }

  .filterleft {
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    li {
      span {
        padding: 0;
        color: #000;
        font-size: 15px;
        margin: 0px 10px 8px 20px;
        margin-left: 20px;
        margin-right: 10px;
        font-weight: normal;
        display: inline-block;
        cursor: pointer;
      }

      .filterbtn {
        padding: 8px 20px;
        margin-right: 10px;
        border-radius: 50px;
        font-size: 14px;
        line-height: 15px;
        color: #000;
        display: inline-flex;
        align-items: center;
        justify-content: space-between;
        font-family: 'pangrammedium';
        letter-spacing: 0.02em;
        text-transform: capitalize;
        position: relative;
        background-color: #f5f5f5;
        transition: 0.3s all ease-in-out;
        margin-bottom: 5px;
        margin-left: 0px;

        &:after {
          content: '';
          width: 7px;
          height: 7px;
          background: #e27a34;
          border-radius: 50%;
          position: absolute;
          top: 8px;
          right: 10px;
          display: none;
        }

        img {
          height: 17px;
        }

        &:hover {
          background-color: #e7e7e7;
        }

        &.active {
          &:after {
            display: inline-block;
          }
        }
      }

      &.toggle-btn {
        padding: 0;
        margin: 0;
        background: unset;
        vertical-align: middle;
        transition: 0.3s all ease-in-out;

        .switch {
          position: relative;
          display: inline-block;
          width: 50px;
          height: 25px;

          input {
            display: none;
          }

          .slider {
            border-radius: 34px;
            position: absolute;
            cursor: pointer;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            transition: 0.4s;
            background-color: #b7b7b7;

            &:before {
              content: '';
              height: 18px;
              width: 18px;
              left: 4px;
              bottom: 4px;
              transition: 0.4s;
              border-radius: 50%;
              position: absolute;
              background-color: white;
              transform: translateX(0px);
            }

            .off,
            .on {
              top: 50%;
              left: 50%;
              font-size: 10px;
              color: white;
              transition: 0.4s;
              user-select: none;
              position: absolute;
              transform: translate(-50%, -50%);
              font-family: Verdana, sans-serif;
            }

            .on {
              display: none;
            }

            &.checked {
              background: linear-gradient(45deg, #ee7120, #ea5039);

              &:before {
                transform: translateX(24px);
              }

              .off {
                display: none;
              }

              .on {
                display: block;
              }
            }
          }
        }

        .title-tag {
          color: #333;
          font-size: 14px;
          vertical-align: top;
          margin: 2px 0px 0 6px;
          display: inline-block;
        }
      }
    }
  }

  .filterright {
    display: flex;
    align-items: center;
    margin-bottom: 5px;

    li {
      span {
        color: #333333;
        font-size: 14px;
        margin-right: 10px;
        text-transform: uppercase;
        letter-spacing: 0.03em;
        display: inline-block;
        white-space: nowrap;
        font-family: 'pangrammedium';
      }

      .filterviewdiv {
        background-color: #fff;
        padding: 0px 10px;
        border-radius: 50px;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
        display: flex;
        align-items: center;
        overflow: hidden;

        .filterviewbtn {
          padding: 10px 10px;
          display: inline-flex;
          border-right: 2px solid #f5f5f5;
          margin-right: 0px;
          cursor: pointer;

          &:nth-last-child(1) {
            border: 0px;
          }

          img {
            width: auto;
            height: auto;
          }

          &.active {
            img {
              filter: brightness(0.5);
            }
          }
        }
      }
    }
  }

  .filter-block {
    display: block;
    border: 0;
    width: 100%;
    display: flex;
    padding: 20px 0;
    align-items: center;
    justify-content: space-between;

    li {
      font-size: 13px;
      line-height: 18px;
      padding: 5px 12px;
      margin: 0px 10px 0px 0;
      border: 0;
      border-radius: 50px;
      display: inline-block;
      letter-spacing: 0.02em;
      cursor: pointer;
      text-transform: capitalize;
      position: relative;
      background: #f5f5f5;
      transition: 0.3s all ease-in-out;
      font-family: "pangramregular";
      color: #333333;

      a {
        color: #333333;
        margin-left: 5px;
        font-family: 'pangramregular';
      }

      &.clearallFilter {
        position: unset;
        font-size: 14px;
        margin: 0;
        pointer-events: auto;
        background: transparent !important;

        a {
          color: #e27a34;
        }
      }
    }
  }
}

.subinner-menu {
  background: #f9f9f9;
  padding: 15px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border-bottom: 1px solid #f5f5f5;
}

.field-cap {
  label {
    text-transform: capitalize !important;
  }
}

.head {
  position: relative;

  .active:before {
    content: '';
    width: 8px;
    height: 8px;
    background-color: #e27a34;
    display: inline-block;
    position: absolute;
    left: -1.4em;
    top: 0;
    border-radius: 50%;
  }
}

@media (max-width: 1400px) {
  .filterssortsection {
    .filterleft {
      li {
        .filterbtn {
          margin-left: 0px;
        }
      }
    }

    .filterright {
      li {
        span {
          font-size: 14px;
          line-height: 18px;
        }

        .filterviewdiv {
          padding: 0px 5px;

          .filterviewbtn {
            padding: 7px 8px;
          }
        }
      }
    }
  }
}

@media (max-width: 1280px) {
  .filterssortsection {
    .filterleft {
      li {
        .filterbtn {
          padding: 6px 14px;
          font-size: 13px;
        }
      }
    }
  }
}

@media (max-width: 1199px) {
  .filterssortsection {
    .filterleft {
      li {
        .filterbtn {
          font-size: 13px;
          padding: 8px 13px;
        }
      }
    }
  }
}

.filter-fixed {
  position: fixed;
  transition: all 0.4s ease;
  left: 0;
  right: 0;
  top: 44px !important;
  z-index: 111;
  width: 100%;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.15);
}